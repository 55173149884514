angular.module('app')
    .factory('departmentService', ["$log", "$http", function ($log, $http) {

        var urls = {
            list: '/dashboard/organisation',
            add: '/dashboard/organisation',
            update: '/dashboard/organisation/%o',
            get: '/dashboard/organisation/%o',
            validateAbnAndGetBusinessName: '/dashboard/organisation/validateAbn/%o',
        };

        var api = {
            list: function (page) {
                $log.info("Listing departments");
                var params = {
                    page: page
                };
                return $http.get(urls.list, {params: params})
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            get: function (organisationReference) {
                $log.debug("getting department with organisationReference: " + organisationReference);
                return $http.get(sprintf(urls.get, organisationReference)).then(function (result) {
                    return result.data;
                });
            },
            add: function (organisation) {
                return $http.post(urls.add, organisation)
                    .then(function (resp) {
                        return resp.data;
                    });

            },
            update: function (organisationReference, organisation) {
                return $http.put(sprintf(urls.update, organisationReference), organisation)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            validateAbnAndGetBusinessName: function (abn) {
                $log.debug("validate abn and get the business name with ABN: " + abn);
                return $http.get(sprintf(urls.validateAbnAndGetBusinessName, abn)).then(function (result) {
                    return result.data;
                });
            }
        };

        return api;
    }]);